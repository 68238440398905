<template>
  <nuxt-link :to="`/tin-tuc/${item.alias}`" class="news-section-item">
    <div class="news-section-item__content">
      <div class="news-section-item__title">{{ item.title }}</div>
    </div>
    <div class="news-section-item__image">
      <CommonBaseImg :src="item?.thumbnail" :alt="item.title" lazy />
      <CommonBaseButton class="btn" @click="router.push(`/tin-tuc/${item.alias}`)">
        Xem Chi Tiết
      </CommonBaseButton>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
import CommonBaseButton from '~/components/common/base-button.vue'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const router = useRouter()
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  item: {
    type: Object,
    default: () => {}
  }
})
</script>
<style scoped lang="scss">
@import 'assets/scss/components/desktop/pages/home/news/news-section-item.scss';
</style>
