export const useNews = () => {
  const { $axios, $pinia, $device } = useNuxtApp()
  const router = useRouter()
  const temp = ref([
    {
      title: 'BẮN CÁ ONLINE',
      posts: [
        {
          alias: 'ky-thuat-ban-ca-de-co-the-chien-thang-giai-thuong-cao-nhat',
          title: 'Kỹ thuật bắn cá để có thể chiến thắng giải thưởng cao nhất',
          desc: 'Bắn cá hình thành theo thời gian chơi người chơi cần tinh ý có những chọn lựa hợp lý thì mới có thể chiến thắng game bắn cá online.',
          created_time: '08-05-2024',
          thumbnail: '/assets/images/components/desktop/pages/home/ban-ca-online.jpg'
        }
      ]
    },
    {
      title: 'NỔ HŨ - SLOTS',
      posts: [
        {
          alias: 'nhung-tinh-nang-va-thuat-ngu-co-trong-slot-game-online',
          title: 'Những tính năng và thuật ngữ có trong slot game online',
          desc: 'Slot game hay còn gọi là nổ hũ. Cùng chúng tôi tìm hiểu những tính năng và những thuật ngữ có trong slot game qua bài viết sau.',
          created_time: '08-05-2024',
          thumbnail: '/assets/images/components/desktop/pages/home/slot-game-online.png'
        }
      ]
    },
    {
      title: 'LIVE CASINO',
      posts: [
        {
          alias: 'mach-ban-dia-chi-choi-live-casino-uy-tin-tot-nhat-2021',
          title: 'Mách bạn địa chỉ chơi Live Casino uy tín, tốt nhất 2024',
          desc: 'Vì sợ vấn đề gian lận, không minh bạch trong các ván bài nên người chơi đã chuyển sang Live casino. Cùng tìm hiểu về Web chơi Live Casino chất lượng nào.',
          created_time: '08-05-2024',
          thumbnail: '/assets/images/components/desktop/pages/home/baccarat-one88.png'
        }
      ]
    }
  ])
  const news = ref(temp.value)
  return {
    news
  }
}
