<template>
  <section class="news">
    <div class="news__container container-custom">
      <SectionTitle title="Tin tức" text-see-more="Xem tất cả" link="/tin-tuc" is-see-more />
      <div class="news__content">
        <div v-for="(item, index) in news" :key="index" class="news-section__item">
          <NewsSectionItem :item="item.posts[0]" :title="item.title" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useNews } from '~~/composables/news/useNews'
import NewsSectionItem from '@/components/desktop/pages/home/news/news-section-item.vue'
const SectionTitle = defineAsyncComponent(() => import('@/components/common/title-section.vue'))
const { news } = useNews()
const router = useRouter()
</script>
<style scoped lang="scss">
@import 'assets/scss/components/desktop/pages/home/news/news.scss';
</style>
